<template>
    <div>
        <div class="grid-x grid-padding-x grid-padding-y">
            <div class="medium-12 large-7 xlarge-8 cell">
                <router-link to="/"><img v-bind:src="require(`../assets/logo-blk.png`)" class="show-for-large"/>
                <img v-bind:src="require(`../assets/logo-blk.png`)" style="width: 200px;" class="show-for-small hide-for-large"/></router-link>

                <ul class="menu align-right float-right show-for-large" style="margin-top: 1em; font-size: 1.2em;">
                    <li><router-link to="/Property">Property</router-link></li>
                    <li><router-link to="/Tenants">Tenants</router-link></li>
                    <li><router-link to="/FindUs">Find Us</router-link></li>
                </ul>
            </div>
            <div class="medium-12 large-5 xlarge-4 cell show-for-large primary-background">
                
            </div>
        </div>
        <div class="grid-x grid-padding-x content">
            <div class="medium-12 large-7 xlarge-8 cell">
                <!-- Disclaimer --->
 <p class="lead">Centennial Partners, LLC</p>
      <p><b>NOTICE: BY PROCEEDING TO USE THIS SERVICE, YOU ACKNOWLEDGE 
        YOUR AGREEMENT WITH THE FOLLOWING TERMS AND CONDITIONS. IF YOU DO NOT 
        AGREE WITH THESE TERMS AND CONDITIONS, THEN DO NOT USE THIS SERVICE.</b></p>
      <p>Copyright Notice and Terms and Conditions </p>
      <p ><router-link to="/Terms">View Terms of Use by 
        Clicking Here</router-link></p>
      <p><b>Copyright Notice</b> </p>
      <p>Reproduction of any of this material is strictly prohibited. 
        Permission to use all documents and related graphics and any other material 
        from this World Wide Web server is subject to the following Terms and 
        Conditions. </p>
      <p><b>Terms and Conditions</b> </p>
      <p>Each document and other material published by Centennial Partners, LLC® on this server may contain other proprietary notices 
        or describe products, services, processes or technologies owned by Centennial Partners, LLC® or third parties. Nothing contained herein shall 
        be construed as granting to the user a license under any copyright, trademark, 
        or other intellectual property right of Centennial Partners, LLC® 
        or any third party, except for the right of use license expressly set 
        forth below in these terms and conditions. </p>
      
      <p>The displayed content information provided free of charge 
        or purchased through this service are provided under a nonexclusive, non-transferable 
        license for printing and use only by the individual who signed onto this 
        service, and only for that individual's personal use. The user is not 
        permitted to commercially exploit the information or transfer it to any 
        third party. Any reprinting, sublicensing, copying, modifying, publishing, 
        assignment, transfer, sales, or other distribution of the information 
        contained within is strictly prohibited without the prior written consent 
        of Centennial Partners, LLC®. The user shall may not copy, reverse 
        engineer, translate, port, modify or make derivative works of the information, 
        in whole or in part. The user shall not rent, disclose, publish, sell, 
        assign, lease, sublicense, market, or transfer the information or any 
        part thereof or use it in any manner not expressly authorized by this 
        Agreement. The user shall not derive or attempt to derive the source code 
        or structure of all or any portion of the information by reverse engineering, 
        disassembly, decompilation or any other means. The user shall not use 
        the information to operate a service bureau or for any other use involving 
        the processing of data of others. The user does not receive any, and Centennial Partners, LLC® retains all, ownership rights in the information. 
        The information is copyrighted and may not be copied, even if modified 
        or merged with other data or software. The user shall not alter or remove 
        any copyright notice or proprietary legend contained in or on the information.      </p>
      <p><b>Consent to Monitoring and Disclosure</b> </p>
      <p>Centennial Partners, LLC® is under no obligation 
        to monitor the information residing on or transmitted to this server. 
        However, anyone using this server agrees that Centennial Partners, LLC® may monitor the server contents periodically to (1) comply with 
        any necessary laws, regulations or other government requests; (2) to operate 
        the server properly or to protect itself and its users. Centennial Partners, LLC® reserves the right to modify, reject or eliminate any information 
        residing on or transmitted to its server that it, in its sole discretion, 
        believes is unacceptable or in violation of these terms and conditions.      </p>
      <p>Should any user of information on this server provide 
        Centennial Partners, LLC® with information including but not limited 
        to feedback, data, answers, questions, comments, suggestions, plans, ideas 
        or the like, such information shall be deemed to be nonconfidential and 
        Centennial Partners, LLC® assumes no obligation to protect such 
        information from disclosure. The submission of such information to Centennial Partners, LLC® shall in no way prevent the purchase, manufacture 
        or use of similar products, services, plans and ideas by Centennial Partners, LLC® for any purpose whatever and by such submission the user grants 
        to Centennial Partners, LLC® the irrevocable right to reproduce, 
        use, disclose and distribute the information to others without restriction 
        or compensation required. </p>
      <p><b>Disclaimer of Liability</b> </p>
      <p>THE USER OF THIS SYSTEM ASSUMES ALL RESPONSIBILITY AND 
        RISK FOR THE USE OF THIS SERVER AND THE INTERNET GENERALLY. Centennial Partners, LLC, ITS AFFILIATES AND DATA SUPPLIERS DISCLAIM ALL WARRANTIES, 
        REPRESENTATIONS OR ENDORSEMENTS, EXPRESS OR IMPLIED, WITH REGARD TO THE 
        INFORMATION ACCESSED FROM, OR VIA, THIS SERVER OR THE INTERNET, INCLUDING, 
        BUT NOT LIMITED TO, ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS 
        FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. Centennial Partners, 
        Inc® DOES NOT ASSUME ANY LEGAL LIABILITY OR RESPONSIBILITY FOR THE ACCURACY, 
        COMPLETENESS, OR USEFULNESS OF ANY INFORMATION, APPARATUS, PRODUCT OR 
        PROCESS DISCLOSED ON THE SERVER OR OTHER MATERIAL ACCESSIBLE FROM THE 
        SERVER. IN NO EVENT SHALL Centennial Partners, LLC® BE LIABLE FOR 
        ANY COMPENSATORY, SPECIAL, DIRECT, INCIDENTAL, INDIRECT, CONSEQUENTIAL 
        DAMAGES, EXEMPLARY DAMAGES OR ANY DAMAGES WHATSOEVER RESULTING FROM LOSS 
        OF USE, DATA, OR PROFITS, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, 
        ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THE INFORMATION 
        ON THIS SERVER OR THE INTERNET GENERALLY OR ON ANY OTHER BASIS.       </p>
      <p><b>No Warranties</b> </p>
      <p>Any Centennial Partners, LLC® material on this 
        server may include technical inaccuracies or typographical errors. Centennial Partners, LLC® has the right to make changes and updates to any 
        information contained within this server without prior notice.      </p>
      <p>THE INFORMATION PROVIDED ON THIS SERVER IS PROVIDED ON 
        AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS WITHOUT WARRANTIES 
        OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES 
        OF TITLE, NONINFRINGEMENT OR IMPLIED WARRANTIES OF MERCHANTABILITY OR 
        FITNESS FOR A PARTICULAR PURPOSE, NO ADVICE OR INFORMATION GIVEN BY CT 
        Enterprise Solutions, Inc®, ITS AFFILIATES OR THEIR RESPECTIVE EMPLOYEES 
        SHALL CREATE ANY WARRANTY. NEITHER Centennial Partners, LLC® NOR ITS 
        AFFILIATES WARRANTS THAT THE INFORMATION ON THIS SERVER OR ON THE INTERNET 
        GENERALLY WILL BE UNINTERRUPTIBLE OR ERROR FREE OR THAT ANY INFORMATION, 
        SOFTWARE OR OTHER MATERIAL ACCESSIBLE FROM THE SERVER IS FREE OF VIRUSES 
        OR OTHER HARMFUL COMPONENTS.  </p>
      <p><b>Disclaimer of Endorsement</b> </p>
      <p>Reference herein to any products, services, processes, 
        hypertext links to third parties or other information by trade name, trademark, 
        manufacturer, supplier or otherwise does not necessarily constitute or 
        imply its endorsement, sponsorship or recommendation by Centennial Partners, LLC. Product and service information are the sole responsibility 
        of each individual vendor. Centennial Partners, LLC® name and logo 
        and other trademarks and trade names may not be used in any commercial 
        manner without the prior written consent of Centennial Partners, LLC®. 
        The user is responsible for providing all software and equipment needed 
        to access this service. </p>
      <p>This is the exclusive Agreement between Centennial Partners, LLC and the user regarding its subject matter. Centennial Partners, LLC shall have the right to terminate user's ability to access 
        this service at any time without notice. Centennial Partners, LLC® 
        reserves the right to discontinue or modify any of the information contained 
        on this service, or the service, at any time. Centennial Partners, 
        Inc® shall have the right to modify these terms and conditions at any 
        time. The user may not assign any part of this Agreement without Centennial Partners, LLC prior written consent. This Agreement shall be governed 
        by the internal laws of Colorado. The user shall pay any taxes on the 
        information or transactions, except for those based on Centennial Partners, 
        Inc®' annual net income. If any provision of this Agreement is declared 
        invalid or unenforceable, the remaining provisions of this Agreement shall 
        remain in effect. The user agrees to comply with all applicable laws in 
        using this service or the information contained herein. No waiver of any 
        obligation or right of either party shall be effective unless in writing, 
        executed by the party against whom it is being enforced. Any such waiver 
        shall not preclude a party from exercising any other right or later exercising 
        the same right. Any notice under this Agreement shall be delivered by 
        U.S. certified mail, return receipt requested, or by overnight courier 
        to Centennial Partners, LLC®. These terms and conditions apply on 
        behalf of Centennial Partners, LLC® and all of its affiliates, employees, 
        suppliers, and distributors. </p>
      <p><b>U.S. GOVERNMENT RESTRICTED RIGHTS</b> </p>
      <p>The information is provided to the user with RESTRICTED 
        RIGHTS. Use, duplication, or disclosure by the Government is subject to 
        restrictions as set forth in subparagraph (c)(1)(ii) of The Rights in 
        Technical Data and Computer Software clause at DFARS 252.227-7013 or subparagraphs 
        (c)(1) and (2) of the Commercial Computer Software--Restricted Rights 
        at 48 CFR 52.227-19, as applicable. Manufacturer is Centennial Partners, 
        Inc®. The information is copyright © 2007 by Centennial Partners, 
        Inc®. UNPUBLISHED. ALL RIGHTS RESERVED UNDER THE COPYRIGHT LAWS OF THE 
        UNITED STATES. For purpose of any public disclosure provision under any 
        federal, state or local law, it is agreed that the information is trade 
        secret and proprietary commercial data and not subject to disclosure.      </p>
      <p>For further information relating to the legal issues contained 
        in these terms and conditions, question or problem reports regarding this 
        service, contact <a href="mailto:info@centennialprofessional.com">Centennial Partners, LLC®</a>.      </p> 
    


            © {{currentYear}} Centennial Partners, LLC Corporation. All rights reserved.

                <!-- END: Disclaimer -->
            </div>
            <div class="medium-12 large-5 xlarge-4 cell show-for-large primary-background text-center">
                <div style="position: fixed; width:30%;">
                    <img v-bind:src="require(`../assets/logo-wht.png`)" style="margin-top: 1.5em; width: 200px;"/>
                    <hr>
                    <p>
                        <h5>Centennial Partners</h5>
                        <p>3470 Centennial Blvd., Suite 100<br>
                        Colorado Springs, CO 80907<br>
                        Email: <a href="mailto:info@centennialprofessional.com" style="color: #fff;">info@centennialprofessional.com</a><br>
                        Bus: <span class="lead">(719) 575-1200</span><br>
                        Fax: <span class="lead">(719) 575-1250</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
   computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '../_settings.scss';
    .content {
        border-top: 3px solid $primary-color;
    }

</style>