<template>
    <div>
        <div class="grid-x grid-padding-x grid-padding-y">
            <div class="medium-12 large-7 xlarge-8 cell">
                <router-link to="/"><img v-bind:src="require(`../assets/logo-blk.png`)" class="show-for-large"/>
                <img v-bind:src="require(`../assets/logo-blk.png`)" style="width: 200px;" class="show-for-small hide-for-large"/></router-link>

                <ul class="menu align-right float-right show-for-large" style="margin-top: 1em; font-size: 1.2em;">
                    <li><router-link to="/Property">Property</router-link></li>
                    <li><router-link to="/Tenants">Tenants</router-link></li>
                    <li class="is-active"><router-link to="/FindUs">Find Us</router-link></li>
                </ul>
            </div>
            <div class="medium-12 large-5 xlarge-4 cell show-for-large primary-background">
                
            </div>
        </div>
        <div class="grid-x grid-padding-x content">
            <div class="medium-12 large-7 xlarge-8 cell">
                <img v-bind:src="require(`../assets/location_map.png`)" style="width:100%;"/>
                <div class="grid-x grid-margin-x grid-margin-y">
                    <div class="small-12 medium-6 cell callout">
                        <h3>Location</h3>
                        <p class="lead">Managed by Centennial Partners</p>
                        <p>3470 Centennial Blvd., Suite 100<br>
                        Colorado Springs, CO 80907</p>
                        <a href="https://www.google.com/maps/dir//3470+Centennial+Blvd,+Colorado+Springs,+Colorado/@38.8780156,-104.8508109,15.75z/data=!4m9!4m8!1m0!1m5!1m1!1s0x8713501faa4d410f:0xecfb5154c4d15c39!2m2!1d-104.8481538!2d38.8808698!3e0"
                         class="button primary" style="text-decoration: none;" target="_blank">Get Directions</a>
                    </div>
                    <div class="small-12 medium-6 cell callout">
                        <h3>Contact Information</h3>
                        <p class="lead">Feel free to contact us by phone or email.</p>
                        <p class="lead"><strong>(719) 575-1200</strong></p>
                        <p style="margin-top:1em;"><a href="mailto:info@centennialprofessional.com">info@centennialprofessional.com</a></p>
                    </div>
                </div>
                <!-- <div class="grid-x grid-margin-x form-background">
                    <div class="small-12 medium-11 medium-offset-1 cell">
                        <h3>Contact Form</h3>
                    </div>
                    <div class="small-12 medium-5 medium-offset-1 cell" >
                        <label>Name</label>
                        <input type="text"/>
                    </div>
                    <div class="small-12 medium-5 cell" >
                        <label>Phone</label>
                        <input type="text" placeholder="format: (xxx) xxx-xxxx"/>
                    </div>
                    <div class="small-12 medium-10 medium-offset-1 cell">
                        <label>Email</label>
                        <input type="text"/>
                    </div>
                    <div class="small-12 medium-10 medium-offset-1 cell">
                        <label>Message</label>
                        <textarea rows="6"></textarea>
                        
                    </div>
                    <div class="small-12 medium-10 medium-offset-1 cell text-right">
                        <button class="button primary large">Send Your Message</button>
                    </div>
                </div> -->
            </div>
            <div class="medium-12 large-5 xlarge-4 cell show-for-large primary-background text-center">
                <div>
                    <img v-bind:src="require(`../assets/logo-wht.png`)" style="margin-top: 1.5em; width: 200px;"/>
                    <hr>
                    <p>
                        <h5>Centennial Partners</h5>
                        <p>3470 Centennial Blvd., Suite 100<br>
                        Colorado Springs, CO 80907<br>
                        Email: <a href="mailto:info@centennialprofessional.com" style="color: #fff;">info@centennialprofessional.com</a><br>
                        Bus: <span class="lead">(719) 575-1200</span><br>
                        Fax: <span class="lead">(719) 575-1250</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    @import '../_settings.scss';

    input {
        background-color: $light-gray;
        height: 3em;
    }
    textarea {
        background-color: $light-gray;
    }
    .form-background {
        background-color: $light-gray;
        padding-top: 1em;
    }
    .content {
        border-top: 3px solid $primary-color;
    }
    .callout {
        border: 1px solid $light-gray;
        border-bottom: 3px solid $medium-gray;
        /* Black shadow with 10px blur */
        filter: drop-shadow(6px 6px 6px $light-gray);
        margin-bottom: 2.8em;
    }
</style>