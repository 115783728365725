<template>
    <div>
        <div class="grid-x grid-padding-x grid-padding-y">
            <div class="medium-12 large-7 xlarge-8 cell">
                <router-link to="/"><img v-bind:src="require(`../assets/logo-blk.png`)" class="show-for-large"/>
                <img v-bind:src="require(`../assets/logo-blk.png`)" style="width: 200px;" class="show-for-small hide-for-large"/></router-link>

                <ul class="menu align-right float-right show-for-large" style="margin-top: 1em; font-size: 1.2em;">
                    <li><router-link to="/Property">Property</router-link></li>
                    <li><router-link to="/Tenants">Tenants</router-link></li>
                    <li><router-link to="/FindUs">Find Us</router-link></li>
                </ul>
            </div>
            <div class="medium-12 large-5 xlarge-4 cell show-for-large primary-background">
                
            </div>
        </div>
        <div class="grid-x grid-padding-x content">
            <div class="medium-12 large-7 xlarge-8 cell text-center" style="min-height:768px; padding-top:4em;">
               <h1>404 Not Found.</h1>
               <p class="lead">The page you were looking for was not found or does not exsist.</p>
               <router-link to="/">Return to our home page</router-link>
            </div>
            <div class="medium-12 large-5 xlarge-4 cell show-for-large primary-background text-center">
                <div>
                    <img v-bind:src="require(`../assets/logo-wht.png`)" style="margin-top: 1.5em; width: 200px;"/>
                    <hr>
                    <p>
                        <h5>Centennial Partners</h5>
                        <p>3470 Centennial Blvd., Suite 100<br>
                        Colorado Springs, CO 80907<br>
                        Email: <a href="mailto:info@centennialprofessional.com" style="color: #fff;">info@centennialprofessional.com</a><br>
                        Bus: <span class="lead">(719) 575-1200</span><br>
                        Fax: <span class="lead">(719) 575-1250</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
   computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '../_settings.scss';
    .content {
        border-top: 3px solid $primary-color;
    }

</style>