import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
//import _ from "lodash";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        callouts: []
    },
    mutations: {
        SET_CALLOUTS(state, payload) {
            state.callouts = payload;
        }
    },
    actions: {
        fetchCallouts({commit}) {
            axios
            .get("./data.json") //local data feed example
            .then(response => (commit("SET_CALLOUTS", response.data)))
            .catch(error => console.log(error));
        },
    }
});