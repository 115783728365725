<template>
    <div>
       <div class="grid-x grid-padding-x grid-padding-y">
            <div class="medium-12 large-7 xlarge-8 cell">
                <router-link to="/"><img v-bind:src="require(`../assets/logo-blk.png`)" class="show-for-large"/>
                <img v-bind:src="require(`../assets/logo-blk.png`)" style="width: 200px;" class="show-for-small hide-for-large"/></router-link>

                <ul class="menu align-right float-right show-for-large" style="margin-top: 1em; font-size: 1.2em;">
                    <li><router-link to="/Property">Property</router-link></li>
                    <li class="is-active"><router-link to="/Tenants">Tenants</router-link></li>
                    <li><router-link to="/FindUs">Find Us</router-link></li>
                </ul>
            </div>
            <div class="medium-12 large-5 xlarge-4 cell show-for-large primary-background">
                
            </div>
        </div>
        <div class="grid-x grid-padding-x grid-padding-y content">
            <div class="small-12 cell">
                <div class="grid-x grid-margin-x">
                    <div v-for="callout in callouts" v-bind:key="callout.id" class="medium-12 large-6 cell callout" >
                        <img v-bind:src="require(`../assets/${callout.image}`)"/>
                        <p class="lead subheader">{{callout.name}}</p>
                        <p>{{callout.description}}</p>

                        <a v-bind:href="callout.url" target="_blank">{{callout.url_name}}</a>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    created() {
        this.$store.dispatch("fetchCallouts");
        console.log(this.callouts);
    },
    computed: {
        ...mapState(["callouts"])
    }
}
</script>

<style lang="scss" scoped>
    @import '../_settings.scss';
    .content {
        border-top: 3px solid $primary-color;
    }
    .callout {
        border: 1px solid $light-gray;
        border-bottom: 3px solid $medium-gray;
        /* Black shadow with 10px blur */
        filter: drop-shadow(6px 6px 6px $light-gray)
    }
</style>