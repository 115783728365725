<template>
  <div id="app"> 

    <div class="off-canvas position-right" id="offCanvas" data-off-canvas data-transition="overlap">
      <!-- Close button -->
      <button class="close-button button large clear" aria-label="Close menu" type="button" style="color: #fff;" data-close>
        <font-awesome-icon class="times" icon="times" />
      </button>

      <!-- Menu -->
      <ul class="vertical menu align-left" style="padding-top: 3em;">
        <li><strong><router-link to="/">Home</router-link></strong></li>
        <li>
          <strong><router-link to="/Property">Property</router-link></strong>
          <!--<ul class="nested vertical menu">
            <li><a href="#">Floor Plan</a></li>
            <li><a href="#">Leasing Informaiton</a></li>
          </ul>-->
        </li>
        <li><strong><router-link to="/Tenants">Tenants</router-link></strong></li>
        <li>
          <strong><router-link to="/FindUs">Find Us</router-link></strong>
        </li>
      </ul>         
    </div>
    <div class="off-canvas-content" data-off-canvas-content> 

      <button type="button" class="bar-btn button large hide-for-print hide-for-large" data-toggle="offCanvas">
        <font-awesome-icon class="bars" icon="bars" />       
      </button>
          
      <transition
        v-on:enter="pageEnter"
        v-on:leave="pageLeave"
        v-on:css="false"
      >
        <router-view></router-view>
      </transition>
      <div class="grid-x">
        <div class="small-12 cell footer">
          <ul class="menu small-align-center align-right">
                <li><a>&copy; {{currentYear}}</a></li>
              <li><router-link to="/Terms">Terms</router-link></li>
              <li><router-link to="/Disclaimer">Disclaimer</router-link></li>
            </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import $ from "jquery"
import Foundation from "foundation-sites"
//import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { gsap } from "gsap"

export default {
  name: "App",
  components: {
    FontAwesomeIcon,
  },
  mounted() {
    this.offCanvas = new Foundation.OffCanvas($('#offCanvas'));
  },
  methods: {
    pageEnter(el, done) {
      gsap.from(el, {duration: .5, alpha: 0, ease: "power1.inOut", onComplete: done });
    },
    pageLeave(el, done) {
      $('#offCanvas').foundation('close');
      gsap.set(el, {alpha: 0, onComplete: done });
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
};
</script>

<style lang="scss">
@import url('https://use.typekit.net/zja2txh.css');
// Setting up Foundation scss based on: https://stackoverflow.com/questions/50844644/configuring-foundation-6-and-vue-cli
@import '_settings.scss';
@import '~foundation-sites/scss/foundation.scss';
@include foundation-everything;

[v-cloak] {
  display: none;
}
.vertical.menu li h4 {
  margin-bottom: 0;
}
.vertical.menu a {
  color: $white;
}
.vertical.menu a:hover {
  color: $medium-gray;
}  
.bar-btn {
  margin-bottom:0;
  position:fixed;
  top:8px;
  right:8px;
  z-index:6;
  border-radius: 30px;
  opacity: .8;
  padding: .42em .5em !important;
}
.primary-border-top-bottom {
  border-top: 3px solid $primary-color;
  border-bottom: 3px solid $primary-color;
}
.primary-border-left-bottom {
  border-left: 3px solid $primary-color;
  border-bottom: 3px solid $primary-color;
}
.primary-border-top {
  border-top: 3px solid $primary-color;
}
.primary-background {
  background-color: $primary-color;
  color: $white;
}
.secondary-background {
  background-color: $secondary-color;
}
hr {
  border: 1px dotted $secondary-color;
  width: 33%;
}
.footer {
  position: relative; 
  background-color:$light-gray;
  border-top: 1px dotted $secondary-color;
}
/*Remove outline that browsers will render*/
button {
  outline: none;
}
.menu .is-active > a {
  background: $white;
  color: $secondary-color;
  border-bottom: 3px solid $secondary-color;
}
</style>
