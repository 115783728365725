<template>
<div>
    <div class="grid-x grid-padding-x grid-padding-y">
        <div class="medium-12 large-7 xlarge-8 cell">
            <img v-bind:src="require(`../assets/logo-blk.png`)" class="show-for-large"/>
            <img v-bind:src="require(`../assets/logo-blk.png`)" style="width: 200px;" class="show-for-small hide-for-large"/>

            <ul class="menu align-right float-right show-for-large" style="margin-top: 1em; font-size: 1.2em;">
                <li><router-link to="/Property">Property</router-link></li>
                <li><router-link to="/Tenants">Tenants</router-link></li>
                <li><router-link to="/FindUs">Find Us</router-link></li>
            </ul>
        </div>
        <div class="medium-12 large-5 xlarge-4 cell show-for-large primary-background">
            
        </div>
    </div>
    <div class="grid-x">
        <div class="small-12 cell secondary-background" >
            <img ref="heroImage" class="primary-border-top-bottom full" v-bind:src="setImg()"/>
        </div>
    </div>
    <div class="grid-x grid-padding-x grid-padding-y">
        <div class="medium-12 large-7 xlarge-8 cell">
            <h1>Welcome</h1>
            <p>The Centennial Professional Building is a Class A medical/professional two-story office building with 24,800 square feet. Located in the affluent and rapidly growing area of Centennial Blvd. and Fillmore hill, this building is beautifully set along the foothills of the majestic Front Range with quick access from I-25. The Centennial Professional Building is also located directly across from the new Sub-Acute Rehabilitation Hospital and Osteopathic Foundation.</p>

            <p>With onsite property management, the Centennial Professional Building is designed to accommodate custom physician suites, as well as professional suites.</p>

            <h4>Contact us today at (719) 575-1200 for more information on the Centennial Professional Building.</h4>
            
        </div>
        <div class="medium-12 large-5 xlarge-4 cell text-center primary-background">
            <img v-bind:src="require(`../assets/logo-wht.png`)" style="margin-top: 1.5em; width: 200px;"/>
            <hr>
            <p>
                <h5>Centennial Partners</h5>
                <p>3470 Centennial Blvd., Suite 100<br>
                Colorado Springs, CO 80907<br>
                Email: <a href="mailto:info@centennialprofessional.com" style="color: #fff;">info@centennialprofessional.com</a><br>
                Bus: <span class="lead">(719) 575-1200</span><br>
                Fax: <span class="lead">(719) 575-1250</span>
            </p>
        </div>
    </div>
</div>
</template>

<script>
import $ from "jquery"
import Foundation from "foundation-sites"
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';

export default {
    data() {
        return {
            currentSize: '',
            oldSize: ''
        }
    },
    mounted() {
        const vm = this;
        this.$nextTick(() => {
            Foundation.MediaQuery = MediaQuery;
            Foundation.MediaQuery._init();

            //Couldn't get Foundation.Interchange() to work as a directive
            //Creating my own version
            //TODO: Convert this to a vue component
            vm.currentSize = Foundation.MediaQuery.current;
            $(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
                vm.currentSize = newSize;
                vm.oldSize = oldSize;
            });
        });       
    },
    methods: {
        setImg() {
            var img = require(`../assets/centennial-professional-building-large.jpg`);
            console.log(this.currentSize);
            switch(this.currentSize) {
                case "small":
                    img =  require(`../assets/centennial-professional-building-small.jpg`);
                    break;
                case "medium":
                    img =  require(`../assets/centennial-professional-building-medium.jpg`);
                    break;
                case "large":
                    img =  require(`../assets/centennial-professional-building-large.jpg`);
                    break;
                case "xlarge":
                    img = require(`../assets/centennial-professional-building-xlarge.jpg`);
                    break;
                case "xxlarge":
                    img =  require(`../assets/centennial-professional-building-alt-xxlarge.jpg`);
                    break;
                case "xxxlarge":
                    img =  require(`../assets/centennial-professional-building-alt-xxxlarge.jpg`);
                    break;
                default:
            }
            return img;
        }
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    }
}
</script>

<style lang="scss" scoped>

</style>