<template>
    <div>
        <div class="grid-x grid-padding-x small-padding-collapse">
            <div class="medium-12 large-7 xlarge-8 cell">
                <router-link to="/"><img v-bind:src="require(`../assets/logo-blk.png`)" class="show-for-large head-pad"/>
                    <img v-bind:src="require(`../assets/logo-blk.png`)" style="width: 200px;" class="show-for-small hide-for-large head-pad"/>
                </router-link>

                <ul class="menu align-right float-right show-for-large head-pad" style="margin-top: 1.7em; font-size: 1.2em;">
                    <li class="is-active"><router-link to="/Property">Property</router-link></li>
                    <li><router-link to="/Tenants">Tenants</router-link></li>
                    <li><router-link to="/FindUs">Find Us</router-link></li>
                </ul>

                <img class="primary-border-top-bottom" v-bind:src="require(`../assets/centennial-professional-building-alt-large.jpg`)" style="width: 100%;"/>

                <div class="grid-x grid-margin-x head-pad">
                    <div class="small-12 cell">
                        <h1>Property</h1>
                    </div>
                    <div class="auto callout cell show-for-medium" v-bind:class="[isHighlights ? 'callout-selected' : '']">
                        <button v-on:click="selectPropertyInfos('highlights')" class="button clear large expanded">Building Highlights</button>
                    </div>
                    <div class="shrink cell show-for-medium" style="border-right: 1px dotted;"></div>
                    <div class="auto cell callout text-center show-for-medium" v-bind:class="[isFloorPlan ? 'callout-selected' : '']">
                        <button v-on:click="selectPropertyInfos('floorplan')"  class="button clear large expanded">Floor Plan</button>
                    </div>
                    <div class="shrink cell show-for-medium" style="border-right: 1px dotted;"></div>
                    <div class="auto cell callout text-center show-for-medium" v-bind:class="[isLeasing ? 'callout-selected' : '']">
                        <button v-on:click="selectPropertyInfos('leasing')" class="button clear large expanded">Leasing</button>
                    </div>
                    <div class="small-12 cell show-for-small-only">
                        <button v-on:click="selectPropertyInfos('highlights')" class="button large expanded" v-bind:class="[isHighlights ? 'primary' : 'hollow']">Building Highlights</button>
                        <button v-on:click="selectPropertyInfos('floorplan')"  class="button large expanded" v-bind:class="[isFloorPlan ? 'primary' : 'hollow']">Floor Plan</button>
                        <button v-on:click="selectPropertyInfos('leasing')" class="button large expanded" v-bind:class="[isLeasing ? 'primary' : 'hollow']">Leasing</button>
                    </div>
                    <div v-if="isHighlights" class="small-12 cell head-pad">
                        <p class="lead">Located near the corner of Centennial Boulevard and Fillmore Street near the Sub-Acute Rehabilitation Hospital, medical providers in the Centennial Professional Building include Mountain View Medical Group, Medical Rehabilitation Specialists, MDVIP, Kissing Camels Family Dentistry, and Select Physical Therapy.</p>
                        
                        <div class="grid-x grid-margin-x">
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead"><strong>24,800 square feet</strong> of Class A Medical/Professional space in a two-story building.</p>
                                    </div>
                                </div>         
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead"><strong>Elegant</strong> entry stair, elevator serviced</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                 <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead"><strong>Beautiful</strong> rock, stucco and glass curtain wall accents.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary"> 
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="bolt" icon="bolt" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead"><strong>Energy compliant</strong> walls and roof with 1” low “E” insulated glass windows.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="sun" icon="sun" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Sunscreen architectural elements for <strong>reduced solar impact</strong>.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Concrete and steel floors for <strong>reduced noise</strong> transfer and privacy.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Well-zoned mechanical system for <strong>tenant control</strong>.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="wind" icon="wind" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead"><strong>Variable air volume</strong> (VAV) HVAC.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="bolt" icon="bolt" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead"><strong>3-phase power</strong>. Good site and building lighting for 24-hour use.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Coordinate campus master plan (materials, landscaping, etc.).</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Covered front and back entryways.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="street-view" icon="street-view" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">360° views.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="car" icon="car" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead"><strong>Covered reserved parking</strong> for business principals and physicians.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="id-card" icon="id-card" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Card reader/electronic entry access system / building security system.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="car" icon="car" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead"><strong>Easy access</strong> from I-25 and all along the west side from Broadmoor to Peregrine.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="car" icon="car" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Employee parking lot.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Central mail drop.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-12 xlarge-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Rear private entry on 1st floor.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="isFloorPlan" class="small-12 cell head-pad">
                        <p class="lead">Floor Plan Information</p>
                        <div class="grid-x grid-margin-x">
                            <div class="large-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Two-story building totaling 24,800 SF</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Building footprint:12,400 SF</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Suites built to suit</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Tenant improvement is negotiable</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div v-if="isLeasing" class="small-12 cell head-pad">
                         <p class="lead">Rates & Other Leasing Information</p>

                        <div class="grid-x grid-margin-x">
                            <div class="large-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Lease Rate: Depending on term</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Operating Expenses: Currently $9.95/SF</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Tenant Improvements: Negotiable</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="car" icon="car" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Parking: 4.5 spaces per 1,000 SF</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Building Size: 24,800 SF</p>
                                    </div>
                                </div>
                            </div>
                            <div class="large-6 cell callout secondary">
                                <div class="grid-x grid-margin-x">
                                    <div class="shrink cell"><font-awesome-icon class="hotel" icon="hotel" /></div>
                                    <div class="small-10 cell">
                                        <p class="lead">Building Footprint: 12,400 SF</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="medium-12 large-5 xlarge-4 cell show-for-large primary-background text-center">
                <img v-bind:src="require(`../assets/logo-wht.png`)" style="margin-top: 10.5em; width: 200px;"/>
                <hr>
                <p>
                    <h5>Centennial Partners</h5>
                    <p>3470 Centennial Blvd., Suite 100<br>
                    Colorado Springs, CO 80907<br>
                    Email: <a href="mailto:info@centennialprofessional.com" style="color: #fff;">info@centennialprofessional.com</a><br>
                    Bus: <span class="lead">(719) 575-1200</span><br>
                    Fax: <span class="lead">(719) 575-1250</span>
                </p>
                <img class="primary-border-left-bottom" v-bind:src="require(`../assets/full_tower.jpg`)" style="margin-top: 7.8em;"/>
                <img class="primary-border-left-bottom" v-bind:src="require(`../assets/stair_detail.jpg`)"/>
                <img class="primary-border-left-bottom" v-bind:src="require(`../assets/entry-detail.jpg`)"/>
                
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
    data() {
        return {
            isHighlights: true,
            isFloorPlan: false,
            isLeasing: false,
        }
    },
    components: {
        FontAwesomeIcon,
    },
    methods: {
        selectPropertyInfos(tab) {
            switch(tab) {
                case "highlights":
                    this.isHighlights = true;
                    this.isFloorPlan = false;
                    this.isLeasing = false;
                    break;
                case "floorplan":
                    this.isHighlights = false;
                    this.isFloorPlan = true;
                    this.isLeasing = false;
                    break;
                case "leasing":
                    this.isHighlights = false;
                    this.isFloorPlan = false;
                    this.isLeasing = true;
                    break;
                default:
                    this.isHighlights = true;
                    this.isFloorPlan = false;
                    this.isLeasing = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../_settings.scss';

    .head-pad {
        margin: .8em;
    }
    .callout {
        border: 0;
    }
    .callout-selected {
         border-bottom: 6px solid $secondary-color;
    }
    .callout.secondary {
        border-left: 3px solid $secondary-color;
        .hotel,
        .bolt,
        .sun,
        .wind,
        .parking,
        .street-view,
        .id-card,
        .car {
            color: $secondary-color;
        }
    }
</style>